import React, { useEffect, useState } from 'react'
import RootStore from '../../../store/Root'
import Container from '../../layout/Container'
import { observer } from 'mobx-react'
import Table from '../../table/Table'
import Pagination from '../../table/Pagination'
import { TableCellActionViewVision } from '../../table/TableCellEditButton'
import { debounceSearch } from '../../../utils/debounce'
import { TableCellTags } from '../../tag/TableCellTag'
import { NumberToPriceFormatter, TableCellYesNo } from './TableCellAmount'

interface Props {
  rootStore: RootStore,
}

const VisionsPage: React.FC<Props> = ({ rootStore }) => {
    const { visionStore, tagStore, allTagStore, setTitle } = rootStore

    const { searchItem, isApiError, isLoading, apiErrorMessage, fetchVisions, visionData, isPageOpened, excludeTestVisions } = visionStore
    const [searchItemField, setSearchItemField] = useState(searchItem)
    const [excludeTestVisionsField, setExcludeTestVisionsField] = useState(excludeTestVisions)

    const loadPage = (page: number) => {
        fetchVisions(page, searchItem, 'change_page', excludeTestVisionsField)
    }

    const handleOnChangeSearchItem = (e: any) => {
        setSearchItemField(e.target.value.trim())
        debounceSearch(() => fetchVisions(1, e.target.value.trim(), 'search_item', excludeTestVisionsField))
    }

    const handleExcludeTestVisions = (e: any) => {
        const value = e.target.checked
        setExcludeTestVisionsField(value)
        fetchVisions(1, searchItem, 'change_filter', value)
    }

    useEffect(() => {
        setTitle('Visions | OathZephyr')
        if(!isPageOpened){
            fetchVisions(1, '', '', true)
        }
        allTagStore.getAllTags()
    }, [])

    return <Container rootStore={rootStore} redirectIfNotLoggedIn={true}>
        <div className="card">
            <div className="card-header">
                
                <div className='row ml-auto'>
                    Visions
                </div>
            </div>

            <div className="card-body">
                <div className='row'>
                    <div className='col'>
                        <div className='row'>
                            <div className='col-auto my-2 ml-auto pr-0 custom-switch'>
                                <input type="checkbox" className="custom-control-input" name='exclude_test_data' id="excludeTestVisions" onClick={handleExcludeTestVisions} defaultChecked={excludeTestVisionsField} />
                                <label className="custom-control-label pr-2" htmlFor='excludeTestVisions'>Exclude Test Visions</label>
                            </div>
                            <div className='col-auto d-flex'>
                                <input className="form-control mb-4 " name='search' defaultValue={searchItemField} type="text" placeholder='Search' onChange={handleOnChangeSearchItem} aria-label="Search" />
                            </div>

                        </div>
                        {isApiError && <div className='responsive alert-danger p-3 mb-4 rounded' > {apiErrorMessage} </div>}

                        <div className='table-responsive'>
                            <Table
                                isLoading={isLoading}
                                unique_key='_id'
                                columns={[
                                    { name: 'client_name', title: 'Probable Name' },
                                    { name: 'modified_by', title: 'Modified By' },
                                    { name: 'engagement_is_agree_and_signing', title: 'Signed', component: TableCellYesNo },
                                    { name: 'is_invoice_created', title: 'Invoice Created', component: TableCellYesNo },
                                    { name: 'invoice_price', title: 'Invoice Amount', component: NumberToPriceFormatter },
                                    { name: 'is_file_uploaded_to_lm', title: 'File uploaded to LM', component: TableCellYesNo },
                                    { name: 'user_associated_tags', title: 'Tags', component: TableCellTags },
                                    { name: 'createdAt', title: 'CreatedAt' },
                                    { name: 'updatedAt', title: 'UpdatedAt' },
                                    { name: '_id', title: 'Action', component: TableCellActionViewVision }
                                ]}
                                data={visionData.page_items.map((item) => {
                                    return { ...item, tagStore, allTagStore }
                                })}
                            ></Table>
                        </div>

                        <Pagination
                            currentPage={visionData.currentPage}
                            totalPages={visionData.totalPage}
                            totalItems={visionData.totalItem}
                            itemsPerPage={visionData.itemPerPage}
                            isFiltered={visionData.isFiltered}
                            totalAllItems={visionData.totalAllItems}
                            onItemClick={loadPage}
                        ></Pagination>
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

export default observer(VisionsPage)