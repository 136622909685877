export const USER_ROLE_SUPER_ADMIN = 'SUPER_ADMIN'
export const USER_ROLE_EMPLOYEE = 'EMPLOYEE'
export const USER_ROLE_ADMIN = 'ADMIN'
export const USER_ROLE_ADMIN_CMS = 'ADMIN_CMS'
export const USER_ROLE_TEAM_CMS_IPAD = 'TEAM_CMS_IPAD'
export const USER_ROLE_ADMIN_CMS_TEAM_IPAD = 'ADMIN_CMS_TEAM_IPAD'
export const USER_ROLE_DEED_ONLY = 'DEED_ONLY'

export const OPTION_LIST = [{
    label: 'Completed',
    value: 'is_completed',
}, {
    label: 'Reviewed',
    value: 'is_review',
}, {
    label: 'Drafted',
    value: 'is_drafted',
}, {
    label: 'Pending',
    value: 'is_pending',
}, {
    label: 'Working',
    value: 'is_working',
}, {
    label: 'Not Completed',
    value: 'is_not_completed',
}, {
    label: 'Not Reviewed',
    value: 'is_not_review',
}, {
    label: 'Not Drafted',
    value: 'is_not_drafted',
}, {
    label: 'Not Pending',
    value: 'is_not_pending',
}, {
    label: 'Not Working',
    value: 'is_not_working',
}]